<template>
    <!-- <div id="app">
        <div class="app-body"> -->
            <!-- <main>
                <div> -->
                    <transition name="fade" mode="out-in">
                        <router-view />
                    </transition>
                <!-- </div>
            </main> -->
        <!-- </div>
    </div> -->
</template>

<script>
export default {
    name: "BaseContainer",
    data() {
        return {
            isAuthorized: false
        }
    }
}
</script>

<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
